<template>
	<pop-up-wrapper @close="this.$emit('close')">
		<div class="pop-up-show-qcm-img">
			<img
				class="close"
				@click="this.$emit('close')"
				src="/img/close.svg"
				alt=""
			/>
			<img class="upscaled-img" :src="img" alt="">
		</div>	
	</pop-up-wrapper>
</template>

<script>
import PopUpWrapper from '../PopUpWrapper.vue'
export default {
	components: { PopUpWrapper, },
	emits: ['close'],
	props: {
		img: {
			type:String,
			required: true
		},
		
	},
}
</script>

<style>
</style>
