<template>
	<div v-if="imageUrl" class="img-container">
		<img @click="isPopUpImgVisible = !isPopUpImgVisible" :src="imageUrl" alt="">
		<Teleport to="body">
			<PopUpShowImg :img="imageUrl" v-if="isPopUpImgVisible" @close="isPopUpImgVisible = false"/>
		</Teleport>
	</div>
</template>
<script>
import axios from 'axios'
import Config from '../../../../../config'
import PopUpShowImg from '../../../../PopUp/QCM/PopUpShowImg.vue'

export default {
	components: {
		PopUpShowImg
	},
	props: {
		image: {
			type: String,
			required: true
		}
	},
	data() {
		return {
			isPopUpImgVisible: false,
			imageUrl: null
		}
	},

	methods: {
		async getImg(){
			let url = ''
			if(this.image.startsWith('http')){
				url = this.image
			}
			else{
				url = `${Config.backend_url}/media/${this.image}`
			}
			if(Config.backend_url == 'https://api-prod.lecoinduprof.fr'){
				url = url.replace('http://', 'https://')
			}
			const res = await axios.get(url, {responseType: 'blob'})
			.then(blob => blob.data)

			this.imageUrl = URL.createObjectURL(res)
		},	
	},
	created() {
		this.getImg()
	}
}
</script>
<style lang="scss" scoped>
@import '../../../../../assets/scss/student-place/qcm-img.scss';
</style>
