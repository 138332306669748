import { authenticatedApiFetch } from '../utils'


export const getQcmStudentAnswersService = async (qcmId) => {
	const res = await authenticatedApiFetch(`/qcm-student-answers/?all&qcm=${qcmId}`, { method: 'GET' })
		.then(res => res.json())
		.catch(error => console.error(error))
	return res
}

export const filteredQcmStudentAnswersService = async (filter) => {
	const res = await authenticatedApiFetch(`/qcm-student-answers/?all&${filter}`, { method: 'GET' })
		.then(res => res.json())
		.catch(error => console.error(error))
	return res
}
