<template>
	<div class="student-place-qcm-container">
		<FullPageLoader v-if="isLoading"/>
		<div class="chart-container">
			<vue3-chart-js
				v-if="!isLoading"
				:id="doughnutChart.id"
				:type="doughnutChart.type"
				:data="doughnutChart.data"
				:options="doughnutChart.options"
			/>
		</div>
		<p v-if="!isLoading" class="average">Moyenne de la classe : {{ getAverage() }} / {{maxNote}}</p>
		<p v-if="!isLoading" class="average">Médiane de la classe: {{ getMedian() }} / {{maxNote}} </p>
		<div class="questions-container">
			<div class="question-container" v-for="question in qcm.questions" :key="question.id">
				<h2><span v-html="question.question"> </span> <span>({{question.point }} points)</span>    </h2>
				<h3>{{ getResponseAmount(question.id) }} réponse(s)</h3>
				<QCMImage v-if="question.image" :image="question.image" />

				<div class="answers-container">
					<div v-for="answer in question.answers" :key="answer.id">
						<p> <input style="pointer-events: none;" type="checkbox" :checked="answer.is_correct"> <span v-html="answer.answer"></span></p> 
						<div class="bar-container">
							<span :style="[getWidth(getAnswersAmount(answer.id),getResponseAmount(question.id)), getColor(answer.is_correct)]"><p>{{ getAnswersAmount(answer.id) }}</p></span>
						</div>
					</div>
				</div>
			</div>
		</div>
	</div>
</template>

<script>
import { getQCMService } from '../../../../../services/qcmServices'
import { getQcmStudentAnswersService } from '../../../../../services/qcmStudentAnswersService'
import { filteredQCMStudentNotesService } from '../../../../../services/qcmStudentNotesServices'
import FullPageLoader from '../../../../Utils/FullPageLoader.vue'
import QCMImage from './QCMImage.vue'
import Vue3ChartJs from '@j-t-mcc/vue3-chartjs'

export default {
	components: {
		QCMImage,
		FullPageLoader,
		Vue3ChartJs	
	},
	data() {
		return {
			qcm:{},
			questions:[],
			studentAnswers:[],
			isLoading: false,
			interval: null,
			notes: [],
			maxNote: 0,
			doughnutChart: {
				type: "bar",
				data: {
					labels: [
					],
					datasets: [
						{
							label: "Notes",
							data: [],
							fill: false,
							backgroundColor: "#3732AF",
						},
					],
				},
				options: {
					responsive: true,
					maintainAspectRatio: true,
					scales: {
						y: {
							ticks: {
								stepSize: 1,
							},
						},
					},
				},
			}
		}
	},

	methods: {
		getAverage(){
			if (this.notes.length === 0) {
				return 0
			}
			const total = this.notes.length
			const sum = this.notes.reduce((acc, note) => acc + note.note, 0)
			return (sum / total).toFixed(2)
		},
		getMedian(){
			if (this.notes.length === 0) {
				return 0
			}
			const sortedNotes = this.notes.map(note => note.note).sort((a, b) => a - b)
			const middle = Math.floor(sortedNotes.length / 2)
			if (sortedNotes.length % 2 === 0) {
				return (sortedNotes[middle - 1] + sortedNotes[middle]) / 2
			}
			return sortedNotes[middle]
		},
		getWidth(amount, total){
			return `width: ${Math.floor((amount / total) * 50)}%`
		},
		getColor(isCorrect){
			return isCorrect ? 'background-color: #3732AF' : 'background-color: #a6a6a6'
		},
		getResponseAmount(questionId){
			const answers = this.studentAnswers.filter(x => x.question === questionId)
			return answers.length
		},
		getAnswersAmount(answerId){
			const answers = this.studentAnswers.filter(x => x.answers.includes(answerId))
			return answers.length
		},
		async getQcm() {
			const res = await getQCMService(this.$route.params.id)
			this.qcm = res
			for (const question of this.qcm.questions) {
				this.maxNote += question.point
			}
			this.doughnutChart.data.labels = Array.from({length: this.maxNote + 1}, (_, i) => i.toString())
			this.doughnutChart.data.datasets[0].data = Array.from({length: this.maxNote + 1}, () => 0)

		},
		
		async getStudentsAnswers(){
			const res = await getQcmStudentAnswersService(this.$route.params.id)
			this.studentAnswers = res
			this.interval = setInterval(async () => {
				const res = await getQcmStudentAnswersService(this.$route.params.id)
				this.studentAnswers = res
			}, 10000);
		},
		async getNotes(){
			const filter = `qcm=${this.$route.params.id}`
			this.notes = await filteredQCMStudentNotesService(filter)
			for(const note of this.notes){
				this.doughnutChart.data.datasets[0].data[note.note] += 1
			}
		}
	},
	async created() {
		this.isLoading = true
		await this.getQcm()
		this.getStudentsAnswers()
		await this.getNotes()
		this.isLoading = false
		
	},
	computed: {
		getStudentQcmFinished(){
			return this.studentAnswers.length > 0
		}
	},
	beforeUnmount() {
		clearInterval(this.interval)
	}
}
</script>

<style scoped lang="scss">
@import '../../../../../assets/scss/student-place/qcm.scss';
</style>
